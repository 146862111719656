import React from 'react';

function Pricing() {
  return (
    <div>
      <div className="row mt-3 mb-5 mx-5 text-center">
        <div className="col-md-6 my-2">
          <div className="m-3">
            <img
              src="https://img.icons8.com/ios/90/000000/developer.png"
              width="50px"
              alt="Real-Time Gold & Silver Prices JSON API"/>
          </div>
          <h2><span className="hl-silver font-weight-bold">Sandbox</span> <span className="text-muted">Plan</span></h2>
          <h3 className="my-3 text-muted">Free</h3>
          <span className="small">Up to <span className="font-weight-bold">100/reqs</span> per Month</span><br/>
          <span className="small">Daily <span className="font-weight-bold">Historical</span> Prices</span><br/>
          <span className="small"><span className="font-weight-bold">2 sec</span> Update Interval</span><br/>
          <span className="small"><span className="font-weight-bold">Limited</span> Support</span><br/>
        </div>
        <div className="col-md-6 my-2">
          <div className="m-3">
            <img
              src="https://img.icons8.com/ios/90/000000/for-beginner.png"
              width="50px"
              alt="Historical & Real-Time Gold & Silver Prices RESTful API"/>
          </div>
          <h2><span className="hl-gold font-weight-bold">Unlimited</span> <span className="text-muted">Plan</span></h2>
          <h3 className="my-3"><sup className="text-muted">$</sup>99<sub>&nbsp;<span className="small text-muted">USD/month</span></sub></h3>
          <span className="small"><span className="font-weight-bold">Unlimited/reqs</span> per Month</span><br/>
          <span className="small">Daily <span className="font-weight-bold">Historical</span> Prices</span><br/>
          <span className="small"><span className="font-weight-bold">Real-Time</span> Updates</span><br/>
          <span className="small"><span className="font-weight-bold">Custom</span> Support</span><br/>
        </div>
      </div>
    </div>
  );
};

export default Pricing;